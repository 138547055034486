.navigation {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.navigation__links {
  display: flex;
  align-items: center;
  gap: 16px;
}

.navigation__link {
  display: flex;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #fff;
  text-decoration: none;
}

.navigation__link_active {
  font-weight: 500;
}

.navigation__link_type_main {
  display: none;
}

.navigation__profile {
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 7px;
}

.navigation__profile-text {
  border: 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  color: #fff;
}

.navigation__profile-icon {
  background: url(../../../images/header-account.svg) center no-repeat;
  background-size: 12px 12px;
  min-width: 26px;
  min-height: 26px;
  background-color: #313131;
  padding: 0;
  border: 0;
  border-radius: 50%;
  margin-right: 3px;
}

.navigation__profile-icon_color_blue {
  background-color: #0F4157;
}

@media all and (max-width: 768px) {
  .navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  .navigation__links {
    display: none;
  }

  .navigation__links_visible {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .navigation__link_type_main {
    display: flex;
  }

  .navigation__link {
    margin-bottom: 28px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21.78px;
  }

  .navigation__link_active {
    text-decoration: underline;
    text-underline-offset: 9px;
  }

  .navigation__profile {
    display: none;
  }

  .navigation__profile_visible {
    display: flex;
    align-items: none;
    margin-bottom: 90px;
  }

  .navigation__profile-text {
    margin: 9.5px 0 9.5px 11px;
  }

  .navigation__profile-icon {
    margin-right: 0;
  }
}

@media all and (max-width: 540px) {
  .navigation {
    margin-bottom: 46px;
  }

  .navigation__link_active {
    text-underline-offset: 11px;
  }

  .navigation__link {
    margin-bottom: 24px;
  }

  .navigation__profile_visible {
    margin-bottom: 0;
  }
}
