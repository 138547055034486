.portfolio {
  max-width: calc(100% - 140px);
  margin: 100px auto 198px;
}

.portfolio__title {
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #8b8b8b;
  margin-top: 0;
  margin-bottom: 50px;
}

.portfolio__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.portfolio__link {
  text-decoration: none;
  display: flex;
  justify-content: space-between;
}

.portfolio__project-name {
  display: flex;
  font-weight: 400;
  font-size: 30px;
  line-height: 50px;
  letter-spacing: -1.2px;
  color: #fff;
  margin: 0;
}

.portfolio__project-name_margin_l {
  margin-top: 2px;
}

.portfolio__link-icon {
  display: flex;
  background: url(../../../images/portfolio-link-icon.svg) center no-repeat;
  min-width: 29px;
  min-height: 59px;
  background-color: #202020;
  margin-right: 1px;
}

.portfolio__line {
  margin-top: 9px;
  margin-bottom: 20px;
  height: 1px;
  background-color: #424242;
  box-sizing: border-box;
}

@media all and (max-width: 768px) {
  .portfolio {
    max-width: calc(100% - 100px);
    margin: 90px auto 162px;
  }

  .portfolio__title {
    color: #a0a0a0;
  }

  .portfolio__project-name {
    font-size: 28px;
    letter-spacing: -1.12px;
  }

  .portfolio__link-icon {
    min-width: 27px;
  }
}

@media all and (max-width: 560px) {
  .portfolio {
    max-width: calc(100% - 28px);
    margin: 70px auto 149px;
  }

  .portfolio__title {
    font-size: 14px;
    margin-bottom: 40px;
  }

  .portfolio__project-name {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.72px;
  }

  .portfolio__link-icon {
    margin-top: -4px;
    min-height: 0;
    min-width: 18px;
    background-size: 10px 13px;
  }

  .portfolio__line {
    margin-top: 17px;
  }
}
