.footer {
  width: calc(100% - 140px);
  margin: 0 auto;
}

.footer_margin_l {
  margin-top: 159px;
}

.footer_margin_xl {
  margin-top: 319px;
}

.footer__text {
  font-weight: 400;
  font-size: 13px;
  line-height: 15.73px;
  color: #8b8b8b;
  border-bottom: 1px solid #424242;
  box-sizing: border-box;
  padding-bottom: 20px;
  text-align: center;
  margin: 0;
}

.footer__info {
  margin-top: 18px;
  margin-bottom: 20px;
  color: #fff;
  display: flex;
  justify-content: space-between;
}

.footer__year {
  font-weight: 400;
  font-size: 13px;
  line-height: 15.73px;
  margin: 2px 0 0;
}

.footer__links {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
}

.footer__list {
  list-style: none;
}

.footer__link {
  font-weight: 400;
  font-size: 13px;
  line-height: 15.73px;
  text-decoration: none;
  color: #fff;
}

@media all and (max-width: 800px) {
  .footer_margin_xl {
    margin-top: 230px;
  }
}

@media all and (max-width: 768px) {
  .footer {
    width: calc(100% - 60px);
  }
}

@media all and (max-width: 450px) {
  .footer {
    width: calc(100% - 20px);
  }

  .footer__text {
    font-size: 12px;
    line-height: 14.52px;
  }

  .footer__info {
    margin-top: 28px;
    flex-direction: column-reverse;
    align-items: center;
    gap: 30px;
  }

  .footer__year {
    margin-top: 0;
    color: #8b8b8b;
    font-size: 12px;
    line-height: 14.52px;
  }

  .footer__links {
    flex-direction: column;
    align-items: center;
    gap: 9px;
  }

  .footer__link {
    font-size: 12px;
    line-height: 14.52px;
  }
}
