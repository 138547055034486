@keyframes preloader-inside-white {
  0% {
      -webkit-transform: scale(0, 0);
      -moz-transform: scale(0, 0);
      -ms-transform: scale(0, 0);
      -o-transform: scale(0, 0);
      transform: scale(0, 0);
  }
  100% {
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
      transform: scale(1, 1);
  }
}

@keyframes preloader-inside-red {
  0% {
      -webkit-transform: scale(0, 0);
      -moz-transform: scale(0, 0);
      -ms-transform: scale(0, 0);
      -o-transform: scale(0, 0);
      transform: scale(0, 0);
  }
  30% {
      -webkit-transform: scale(0, 0);
      -moz-transform: scale(0, 0);
      -ms-transform: scale(0, 0);
      -o-transform: scale(0, 0);
      transform: scale(0, 0);
  }
  100% {
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
      transform: scale(1, 1);
  }
}

.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 120px);
  height: 100%;
  margin: 0 auto;
  z-index: 10;
  padding: 20px;
}

.preloader_margin_l {
  margin-top: 100px;
  margin-bottom: 300px;
}

.preloader__container {
  display: flex;
  width: 100px;
  height: 100px;
  position: relative;
}

.preloader__round {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  background: #4285F4;
  border-radius: 50px;
}

.preloader__round::after,
.preloader__round::before {
  content: '';
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50px;
}

.preloader__round::after {
  background: white;
  animation: preloader-inside-white 1s ease-in-out infinite;
}

.preloader__round::before {
  z-index: 10;
  background: #3DDC84;
  animation: preloader-inside-red 1s ease-in-out infinite;
}
