.title {
  margin: 1px 0 0;
  padding-bottom: 23px;
  border-bottom: 1px solid #dadada;
  box-sizing: border-box;
  color: #fff;
  font-weight: 400;
  font-size: 22px;
  line-height: 26.63px;
  letter-spacing: -0.88px;
}

@media all and (max-width: 768px) {
  .title {
    margin-top: 0;
    padding-top: 0;
  }
}

@media all and (max-width: 450px) {
  .title {
    padding-bottom: 28px;
    font-size: 18px;
    line-height: 21.78px;
    letter-spacing: -0.72px;
  }
}
