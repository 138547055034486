.card {
  max-width: 270px;
  display: flex;
  flex-direction: column;
  list-style: none;
  transition: visibility 0.7s ease-in;
}

.card__image {
  width: 270px;
  height: 151px;
  display: flex;
  object-fit: cover;
  object-position: center;
}

.card__items {
  display: flex;
  justify-content: space-between;
}

.card__title {
  display: flex;
  margin-top: 14px;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 15.73px;
  color: #fff;
}

.card__like-button {
  background: url(../../images/like.svg) no-repeat;
  background-size: cover;
  min-width: 24px;
  min-height: 24px;
  border: 0;
  margin: 10px 0 25px 20px;
  padding: 0;
}

.card__like-button_active {
  background: url(../../images/like-active.svg) no-repeat;
}

.card__delete-button {
  background-color: #202020;
  min-width: 24px;
  min-height: 24px;
  border: 0;
  margin: 10px 0 25px 20px;
  padding: 0;
  opacity: 0;
  transition: opacity 0.5s ease;
  background: url(../../images/delete-button.svg) no-repeat;
  cursor: pointer;
}

.card:hover .card__delete-button {
  opacity: 1;
}

.card__line {
  max-width: 100%;
  min-height: 1px;
  background-color: #424242;
}

.card__duration {
  margin-top: 6px;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 11px;
  line-height: 13.31px;
  color: #8b8b8b;
}

@media all and (max-width: 800px) {
  .card {
    max-width: 339px;
  }

  .card__image {
    width: 339px;
    height: 190px;
  }

  .card__delete-button {
    opacity: 1;
  }
}

@media all and (max-width: 410px) {
  .card {
    max-width: 300px;
  }

  .card__image {
    width: 300px;
    height: 168px;
  }

  .card__title {
    margin-top: 15px;
    font-size: 12px;
    line-height: 14.52px;
  }

  .card__duration {
    font-size: 10px;
    line-height: 12.1px;
  }
}
