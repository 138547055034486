.about-me {
  color: #fff;
  max-width: calc(100% - 140px);
  margin: 110px auto 0;
}

.about-me__title {
  font-weight: 400;
  font-size: 22px;
  line-height: 26.63px;
  padding-bottom: 23px;
  border-bottom: 1px solid #dadada;
  margin: 0;
}

.about-me__info {
  display: flex;
  justify-content: space-between;
  margin-top: 66px;
}

.about-me__description {
  max-width: 600px;
}

.about-me__name {
  font-weight: 400;
  font-size: 50px;
  line-height: 58px;
  letter-spacing: -2px;
  margin: 0;
}

.about-me__specialty {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  margin-top: 18px;
  margin-bottom: 26px;
}

.about-me__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 78px;
}

.about-me__profile-link {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 16.94px;
}

.about-me__image {
  min-width: 270px;
  max-height: 327px;
  object-fit: cover;
  border-radius: 10px;
}

@media all and (max-width: 1070px) {
  .about-me__description {
    max-width: 450px;
  }

  .about-me__text {
    margin-bottom: 35px;
  }
}

@media all and (max-width: 900px) {
  .about-me__info {
    gap: 49px;
  }

  .about-me__description {
    max-width: 364px;
  }
}

@media all and (max-width: 800px) {
  .about-me {
    max-width: calc(100% - 100px);
    margin: 90px auto;
  }

  .about-me__name {
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -1.6px;
  }

  .about-me__specialty {
    font-size: 12px;
    line-height: 18px;
    margin-top: 16px;
    margin-bottom: 20px;
  }

  .about-me__text {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 69px;
  }

  .about-me__image {
    min-width: 255px;
    max-height: 307px;
  }
}

@media all and (max-width: 540px) {
  .about-me {
    max-width: calc(100% - 28px);
    margin: 70px auto;
  }

  .about-me__info {
    margin-top: 60px;
    gap: 40px;
    flex-direction: column-reverse;
    align-items: center;
  }

  .about-me__name {
    font-size: 30px;
    line-height: 36.31px;
    letter-spacing: -1.2px;
  }

  .about-me__specialty {
    margin-top: 20px;
    font-size: 11px;
    line-height: 16px;
  }

  .about-me__text {
    font-size: 11px;
    line-height: 16px;
    margin-bottom: 24px;
  }

  .about-me__image {
    min-width: 292px;
    max-height: 352px;
  }
}
