.techs {
  color: #fff;
  margin: 0 auto;
  background-color: #272727;
  max-width: 100%;
}

.techs__container {
  padding: 100px 70px;
}

.techs__list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 90px;
}

.techs__title {
  font-weight: 400;
  font-size: 50px;
  line-height: 58px;
  margin: 0;
  letter-spacing: -2px;
}

.techs__list {
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 90px);
  grid-auto-rows: auto;
  gap: 10px;
  margin: 0 auto;
  padding: 0;
}

.techs__subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.56px;
  text-align: center;
  max-width: 460px;
  margin-top: 26px;
  margin-bottom: 100px;
}

@media all and (max-width: 768px) {
  .techs__container {
    padding: 93px 50px 90px;
  }

  .techs__list-container {
    margin-top: 80px;
  }

  .techs__subtitle {
    margin-top: 22px;
    margin-bottom: 83px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.48px;
  }

  .techs__list {
    grid-template-columns: repeat(auto-fit, 84px);
  }
}

@media all and (max-width: 450px) {
  .techs__container {
    padding: 70px 18px;
  }

  .techs__list-container {
    margin-top: 60px;
  }

  .techs__title {
    font-size: 30px;
    line-height: 36.31px;
    letter-spacing: -1.2px;
  }

  .techs__subtitle {
    margin-top: 24px;
    margin-bottom: 50px;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: -0.44px;
  }
}

@media all and (max-width: 400px) {
  .techs__list {
    max-width: 178px;
  }
}
