.techs__item {
  background-color: #303030;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  min-width: 90px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
  letter-spacing: -0.56px;
}

@media all and (max-width: 768px) {
  .techs__item {
    font-size: 12px;
    line-height: 14.52px;
    letter-spacing: -0.48px;
    min-height: 57px;
    min-width: 84px;
  }
}
